import bg1 from 'assets/img/1.png';
import bg2 from 'assets/img/2.png';
import bg3 from 'assets/img/3.png';
import bg4 from 'assets/img/4.png';
import bg5 from 'assets/img/5.png';
import { Illustration, MainIllustration } from 'src/routes/Welcome/styled';

const headerHeight = 72;
const headerHeightSm = 64;

export const mainFooterHeight = {
    lg: 200,
    md: 200,
    sm: 200,
    xs: 200,
};
export const secondaryFooterHeight = {
    lg: 110,
    md: 110,
    sm: 131,
    xs: 207,
};
export const cookieBannerHeight = {
    lg: 72,
    md: 72,
    sm: 92,
    xs: 120,
    xxs: 160,
};

const breadcrumbHeight = 70;
const pageTopPadding = 70;
const pageBottomPadding = 25;

export const page = {
    padding: {
        top: pageTopPadding,
        bottom: pageBottomPadding,
    },
    breadcrumbs: {
        height: breadcrumbHeight,
    },
    footer: {
        cookieBannerHeight,
        mainFooterHeight,
        secondaryFooterHeight,
    },
    header: {
        height: headerHeight,
        heightSm: headerHeightSm,
    },
    welcome: {
        navOpacity: 0.8,
        backgroundOpacity: 0.2,
        pages: [
            {
                background: bg1,
                title: 'welcome_screen_title_welcome',
                body: 'welcome_screen_text_welcome',
                illustration: <MainIllustration id="il_welcome_logo" title={'welcome_screen_title_welcome'} />,
                skipCircles: true,
            },
            {
                background: bg2,
                title: 'SLIDE_TITLE_1',
                body: 'SLIDE_BODY_1',
                illustration: <Illustration id="il_welcome_hs" title={'SLIDE_TITLE_1'} />,
            },
            {
                background: bg3,
                title: 'welcome_screen_title_tracking',
                body: 'SLIDE_BODY_2',
                illustration: <Illustration id="il_welcome_navigator" title={'welcome_screen_title_tracking'} />,
            },
            {
                background: bg4,
                title: 'SLIDE_TITLE_3',
                body: 'SLIDE_BODY_3',
                illustration: <Illustration id="il_welcome_trophy" title={'SLIDE_TITLE_3'} />,
            },
            {
                background: bg5,
                title: 'welcome_screen_title_privacy',
                body: 'welcome_screen_text_privacy',
                illustration: <Illustration id="il_welcome_privacy" title={'welcome_screen_title_privacy'} />,
            },
        ],
    },
};
